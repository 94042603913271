// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("/Users/stuartflanagan/Documents/Projects/Newer Volcanics/Site/newervolcanics/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-people-js": () => import("/Users/stuartflanagan/Documents/Projects/Newer Volcanics/Site/newervolcanics/src/templates/people.js" /* webpackChunkName: "component---src-templates-people-js" */),
  "component---src-templates-discography-js": () => import("/Users/stuartflanagan/Documents/Projects/Newer Volcanics/Site/newervolcanics/src/templates/discography.js" /* webpackChunkName: "component---src-templates-discography-js" */),
  "component---src-templates-post-js": () => import("/Users/stuartflanagan/Documents/Projects/Newer Volcanics/Site/newervolcanics/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-release-js": () => import("/Users/stuartflanagan/Documents/Projects/Newer Volcanics/Site/newervolcanics/src/templates/release.js" /* webpackChunkName: "component---src-templates-release-js" */),
  "component---src-templates-recording-js": () => import("/Users/stuartflanagan/Documents/Projects/Newer Volcanics/Site/newervolcanics/src/templates/recording.js" /* webpackChunkName: "component---src-templates-recording-js" */),
  "component---src-templates-person-js": () => import("/Users/stuartflanagan/Documents/Projects/Newer Volcanics/Site/newervolcanics/src/templates/person.js" /* webpackChunkName: "component---src-templates-person-js" */),
  "component---src-templates-group-js": () => import("/Users/stuartflanagan/Documents/Projects/Newer Volcanics/Site/newervolcanics/src/templates/group.js" /* webpackChunkName: "component---src-templates-group-js" */),
  "component---src-templates-media-gallery-js": () => import("/Users/stuartflanagan/Documents/Projects/Newer Volcanics/Site/newervolcanics/src/templates/media-gallery.js" /* webpackChunkName: "component---src-templates-media-gallery-js" */),
  "component---src-pages-404-js": () => import("/Users/stuartflanagan/Documents/Projects/Newer Volcanics/Site/newervolcanics/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import("/Users/stuartflanagan/Documents/Projects/Newer Volcanics/Site/newervolcanics/.cache/data.json")

